.react-calendar {
    min-width: 90%;
    max-width: 100%;
    background-color: white;
    color: #222;
    border-radius: 8px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    font-family: var(--subtitle-font);
    font-size: 1.2rem;
    line-height: 1.125em;
    margin: 10px;
}

.react-calendar__navigation button {
    color: var(--secondary-color);
    min-width: 44px;
    background: none;
    font-size: 1.25rem;
    margin-top: 8px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: var(--secondary-color);
    color: var(--text-color);
}
.react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
}
abbr[title] {
    text-decoration: none;
}

.react-calendar__tile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative; /* Add relative positioning */
    min-height: 50px;
}

.react-calendar__tile abbr {
    margin-top: auto; /* Push the day numbers to the top */
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflow text */
    text-overflow: ellipsis; /* Display ellipsis for overflow text */
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background: var(--text-color);
    color: var(--secondary-color);
    border-radius: 6px;
}
.react-calendar__tile--now {
    background: var(--secondary-color);
    border-radius: 6px;
    font-weight: bold;
    color: var(--primary-color);
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: var(--primary-color);
    border-radius: 6px;
    font-weight: bold;
    color: var(--secondary-color);
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: var(--text-color);
}
.react-calendar__tile--active {
    background: var(--primary-color);
    border-radius: 6px;
    font-weight: bold;
    color: var(--text-color);
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: var(--primary-color);
    color: var(--text-color);
}

.react-calendar__month-view__days__day--weekend{
    color: var(--primary-color);
}

