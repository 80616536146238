@font-face {
    font-family: 'IndustryIncBase';
    src: url('./fonts/IndustryInc-Base.otf') format('opentype');
}

@font-face {
    font-family: 'AntarcticanMonoBook';
    src: url('./fonts/AntarcticanMono-Book.eot');
    src: url('./fonts/AntarcticanMono-Book.eot?#iefix') format('embedded-opentype'),
    url('./fonts/AntarcticanMono-Book.woff2') format('woff2'),
    url('./fonts/AntarcticanMono-Book.woff') format('woff'),
    url('./fonts/AntarcticanMono-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    margin: 0 auto;
}

@media only screen and (max-width: 700px) {
    .container img {
        max-width: 95%; /* Set maximum width to the width of the viewport */
    }
}

.page {
    margin: 0 auto;
    max-width: 1000px;
    padding: 10px;
    color: var(--primary-color);
}

.page h1 {
    font-family: var(--headline-font);
}

.page p {
    font-family: var(--body-font);
}

.page ul {
    font-family: var(--body-font);
}

