/* General styles */
h1 {
    font-family: var(--headline-font);
}

p {
    font-family: var(--body-font);
}

/* HomePage styles */
.homePage {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Responsive grid */
    overflow: auto; /* Add overflow property to prevent grid items from overflowing */
}

.block {
    padding: 20px;
    word-wrap: break-word;
    text-align: center;
}

.blockOne {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.blockDark {
    background-color: var(--primary-color);
    color: var(--text-color);
}

.blockLight {
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.blockWhite {
    background-color: var(--text-color);
    color: var(--primary-color);
}

.logoContainer {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logoImage {
    width: 100%;
    max-width: 500px;
    height: auto;
}

@media screen and (max-width: 768px) {
    .homePage {
        grid-template-columns: repeat(1, 1fr); /* 1 column */
    }

    .logoImage {
        width: 100%;
        max-width: 500px;
        height: auto;
    }
}
