.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
}

.calendarContainer {
    width: 100vw;
    max-width: 1000px;
    /*min-width: 75%;*/
    /*max-width: 95%;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.submitButton {
    background-image: linear-gradient(to right, var(--secondary-color), var(--primary-color)); /* Gradient from left to right */
    color: var(--text-color);
    opacity: 1;
    font-weight: bold;
    font-size: 90%;
    font-family: var(--body-font);
    margin-bottom: 20px;
    margin-top: 10px;

    border: none;
    padding: 10px 40px;
    cursor: pointer;

    /*max-width: 100%;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 200px;
    /*margin: 0 auto;*/
    border-radius: 25px;

    &:hover {
        background-image: linear-gradient(to right, var(--secondary-color), var(--text-color));
        color: var(--primary-color);
    }
}

.eventOnDay {
    color: #467599;
    overflow: hidden; /* Hide overflow text */
    text-overflow: ellipsis; /* Display ellipsis for overflow text */
    white-space: normal; /* Allow text wrapping */
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    width: 100%;
    font-size: 0.8em;
}

.selected {
    color: white;
    font-weight: normal;
    font-family: var(--body-font);
}

.blap {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-family: var(--body-font);
    margin-bottom: 10px;
    line-height: 1.5;
}

.registrationContainer {
    display: flex;
    width: 90%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.eventProvinceContainer {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
    gap: 20px;

    p {
        margin: 0;
    }
}

.selectContainer {
    margin-bottom: 20px;

}

.selectContainer select {
    width: 200px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #f8f8f8; /* Dropdown background color */
    cursor: pointer;
    outline: none; /* Remove outline */
}

.selectContainer select:focus {
    border-color: #467599; /* Focus border color */
}

.selectContainer select option {
    padding: 10px;
    &:hover {
        background-color: #1D3354;
        color: white;
    }
}

h2 {
    font-family: var(--headline-font);
}