.page {
    margin: 0 auto;
    padding: 10px;
    max-width: 1000px;
    align-items: center;
    justify-content: center;
}

.page h1 {
    font-family: var(--headline-font);
}

.buttonContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 10px;

}

.divisionButton, .resetDivisionButton {
    font-size: 1.25em;
    font-family: var(--body-font);
    border: 2px var(--primary-color) solid;
    border-radius: 30px;
    color: var(--primary-color);

    &:hover {
        background-color: var(--secondary-color);
        border-color: var(--secondary-color);
        color: var(--text-color);
    }
}

.resetDivisionButton {
    white-space: nowrap;
}


.divisionButton.selected {
    background-color: #1D3354;
    color: white;
}

.styledTable {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: var(--body-font);
    width: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styledTable thead tr {
    background-color: var(--secondary-color);
    color: var(--text-color);
    text-align: left;
    &:hover {
        cursor: pointer;
    }
}

.styledTable th,
.styledTable td {
    padding: 12px 15px;
}

.styledTable tbody tr {
    border-bottom: 1px solid var(--primary-color);
}

.styledTable tbody tr:nth-of-type(even) {
    background-color: #f2f2f2;
}

.styledTable tbody tr:nth-of-type(odd) {
    background-color: #ffffff;
}

.styledTable tbody tr:last-of-type {
    border-bottom: 2px solid var(--secondary-color);
}

.table-container {
    overflow-x: auto;
    max-width: 95%;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    display: flex;
}