body {
    background-color: #f0f0f0;
}

h3 {
    font-family: var(--headline-font);
}
/*img {*/
/*    max-width: 400px;*/
/*}*/

.container {
    max-width: 1000px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
.socialMediaIcons {
    display: flex; /* Display icons in a row */
    justify-content: center; /* Center align the icons horizontally */
    margin-top: 20px; /* Add some space between the form and social media icons */
    padding-bottom: 10px;
}

.socialMediaIcons a {
    margin: 0 10px; /* Add space between each icon */
}

.socialMediaIcons img {
    width: 40px; /* Set a fixed width for the icons */
    height: 40px; /* Set a fixed height for the icons */
}

.page {
    font-size: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    margin: 0 auto;
    color: var(--primary-color);
}

.formContainer {
    display: flex;
    justify-content: space-between; /* To create space between the image and form */
    align-items: center; /* To align items at the top */
    max-width: 100vw;
    margin: 0 10px 0 10px;
}

.contactFormImageContainer {
    flex: 0 0 auto; /* This will allow the image to grow and shrink as needed */
    max-width: 50%; /* Adjust the maximum width of the image container */
}

.contactFormImageContainer img {
    width: 100%; /* Make the image fill the container */
    height: auto; /* Maintain the aspect ratio */
}


.form {
    font-family: var(--body-font);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    min-width: 45%;
}

.inputGroup {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
textarea {
    font-family: var(--body-font);
    width: 95%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 15px;
}

button[type="submit"] {
    background-image: linear-gradient(to right, var(--secondary-color), var(--primary-color)); /* Gradient from left to right */
    color: var(--text-color);
    opacity: 1;
    font-weight: bold;
    font-size: 100%;
    font-family: var(--body-font);

    border: none;
    padding: 10px 20px;
    cursor: pointer;

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    margin: 0 auto;
    border-radius: 25px;

    &:hover {
        background-image: linear-gradient(to right, var(--secondary-color), var(--text-color));
        color: var(--primary-color);
    }
}

@media screen and (max-width: 949px) {
    .contactFormImageContainer {
        display: none;
    }

    .form {
        min-width: 95%;
        max-width: 100vw;
        margin: 0 0;
    }

    .formContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 95%;
        margin: 0 auto;
    }
}