.menu-icon {
    display: none;
    cursor: pointer;
}

.navbar {
    list-style-type: none;
    background-color: var(--primary-color);
    display: flex;
    /*margin: 0;*/
    padding: 0 20px;
    align-items: center;
    justify-content: space-between;
    position: relative; /* Add position: relative */
    z-index: 2; /* Add z-index to ensure navbar appears above background image */
}

.navbar h1 a{
    text-decoration: none;
    position: relative;
    color: var(--text-color);
    font-size: 1.8em;
    font-style: italic;
}

.bar {
    height: 5px;
    width: 30px;
    background-color: var(--text-color);
    margin: 6px 0;
    transition: 0.4s;
}

.navbar.mobile {
    position: relative;
}

.nav-list {
    list-style-type: none;
    display: flex;
    margin: 0;
    align-items: center;
    ul {
        display: flex;
        padding: 0 0 0 10px;
    }
    li {
        margin-bottom: 0;
        display: flex;
        align-items: center;
    }
}

.nav-item {
    color: var(--text-color);
    font-size: 28px;
    font-family: arpona, serif;
    font-weight: 400;
    font-style: normal;
    margin: 0 0 0 39px;
    text-decoration: none;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center; /* Center the text horizontally */
    height: 100%;
}

.nav-item a {
    color: var(--text-color);
    font-size: 28px;
    font-family: var(--headline-font);
    font-weight: 400;
    font-style: normal;
    margin: 0;
    text-decoration: none;
    background-color: transparent;
    border: none;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center; /* Center the text horizontally */
    height: 100%;
}

.nav-item a:hover {
    color: var(--secondary-color);
    cursor: pointer;
}

.navbar .logo {
    height: 100px;
}

@media screen and (max-width: 800px) {
    .menu-icon {
        display: block;
    }

    .nav-list {
        flex-direction: column;
        position: absolute;
        top: 110px;
        left: 0;
        width: 100%;
        background-color: var(--primary-color);
        transition: 0.5s;
        display: none;
    }

    .nav-list.mobile {
        display: flex;
        padding: 0;
    }

    .nav-item {
        width: 100%;
        text-align: center;
        padding: 10px 0;
        border-bottom: 1px solid var(--text-color);
        margin: 0;
    }

    .nav-item:last-child {
        border-bottom: none;
    }

    .bar.open:nth-child(1) {
        transform: rotate(-45deg) translate(-5px, 5px);
    }

    .bar.open:nth-child(2) {
        opacity: 0;
    }

    .bar.open:nth-child(3) {
        transform: rotate(45deg) translate(-10px, -10px);
    }
}
